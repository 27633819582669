@media (max-width:450px) {
    .passwordReenterSec {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .passwordChangeSec {
        width: 100%;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }
    .errSec {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
    }
    .errSec span {
        color: red;
        font-size: 13px;
    }

}