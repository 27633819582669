.userHeader {
    width: 100%;
    height: 4.5rem;
    border-bottom: 1px solid gray;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.profileName {
    display: inline-flex;

}

.profileActionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5rem;
    height: 100%;
}

.profileIcon {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    border: 2px solid gray;
}

.userProfileNotification {
    position: relative;
    border: none;
    outline: none;
    background: transparent;
}

.userProfileNotification::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0.6rem;
    height: 0.6rem;
    background-color: red;
    border-radius: 50%;
}

.qrcodeBtn {
    position: relative;
    border: none;
    outline: none;
    background: transparent;
}

.qrNotificationWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}