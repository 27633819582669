.historyPageBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
    padding: 0 1rem;
}

.transactionNotFounCont {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.transactionNotFounCont img {
    height: 15rem;
    margin-bottom: 2rem;
}

.transactionNotFounCont p {
    color: #80808085;
    font-size: 1.5rem;
    font-weight: 700;
}