@media (max-width:450px) {
    .otpSection {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

    }

    .otpSectionPadding {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 1rem;
        gap: 1.5rem;
    }

    .headerLabels {
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;

    }

    .otpInputsSec {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    .otpInputs {
        width: calc(100%/4);
        height: 5rem;
        border-radius: 6px;
        text-align: center;
        border: 2px solid gray;
    }

    .otpInputs:focus {
        border: none;
        outline: 2px solid #004CA3;
    }

    /* if the otp is invalid */
    .otpInvalidinput {
        border: 2px solid red !important;
    }

    .resendOtpSec {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }

    .resendOtp {
        background: transparent;
        border: none;
        outline: none;
        color: var(--loginBackColor);
        font-size: 15px;
    }














}