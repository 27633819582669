.activeLoader {
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(7px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}
.activeLoaderIcon{
    animation: Spinner infinite ease-in-out 0.7s ;
}
@keyframes Spinner {
    from{
        transform: rotateZ(0deg);
    }to{
        
        transform: rotateZ(360deg);
    }
}