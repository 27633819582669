@media (max-width:450px) {
    .loginPage {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-between ;
        overflow: hidden;
        
    }


    .loginUpperStyle {
        height: 236px;
    }

    .loginForm {
        width: 100%;
        margin: auto;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        padding: 0 1rem;
    }

    .submitBtn {
        width: 94%;
        height: 3rem;
        background: #004CA3;
        color: white;
        font-size: 16px;
        border-radius: 6px;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .signUpsection {
        width: 100%;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;

    }

    .signUpsection a {
        color: var(--loginBackColor);
    }

    .loginBottomStyle {
        position: absolute;
        bottom: 0;
        z-index: -1;

    }

























}