.QrCodeWrapper {
    /* border: 2px solid red; */
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.QrCodeContainer {
    /* border: 2px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.QrCodeContainer img{
    height: 18rem;
}