.inputBox {
    width: 100%;
    height: fit-content;
    position: relative;
}

.inputItem {
    width: 100%;
    height: 3rem;
    border: 1.5px solid gray;
    padding: 7px;
    border-radius: 6px;
    color: var(--loginInputTxt);
}

.inputItem:focus {
    outline: 1.5px solid green;
    border: none;
}

.inputItem[type="password"] {
    padding-right: 3rem;

}

.passwordInput {
    padding-right: 3rem;

}

.inputBox label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    font-size: 14px;
    padding: 0 3px;
}

.inputPostIcon {
    background: none;
    outline: none;
    border: none;
    width: 2.5rem;
    height: 2.3rem;
    position: absolute;
    right: 0;
    top: 13%;
}

.inputPostIcon span {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background: transparent;
}

select {
    background: transparent;
}

.inputBoxTextarea {
    height: 8rem !important;
    resize: none;
}