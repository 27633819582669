@media (max-width:450px) {
    .profileEditPage {
        width: 100%;

    }

    .profileEditBody {
        width: 100%;
        /* height: 100vh; */
        padding: 0 1rem;
        /* margin-top: 3rem; */
        /* border: 2px solid red; */
    }

    .profileEditBody * {
        /* border: 2px solid red; */
    }


    .profileUpdateForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        /* box-shadow: 0 0 27px -12px gray; */
    }

    .profileHeader {
        width: 7rem;
        height: 7rem;
        display: grid;
        place-items: center;
        position: relative;
    }

    .profileHeader img {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        margin: auto;
    }

    .pictureSelectBtn {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        position: absolute;
        bottom: 3px;
        right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background-color: #c4c4c4;
        box-shadow: 0 0 27px -12px gray;
    }
    
    .profileUpdateInputs{
        width: 100%;
        padding: 1rem;
        box-shadow: 0 0 12px 0 #00000040;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 6px;
}
.userIdHeader{
    color: var(--commonColor);
    font-size:20px ;
    font-weight: 700;
}
.inputsSection{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;

}







}