* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (min-width:450px) {
  #mobileVersion {
    display: none;
  }

}

#PcVersion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#PcVersion p {
  color: #80808085;
  font-size: 1.5rem;
  font-weight: 700;
}

@media(max-width:450px) {
  #PcVersion {
    display: none;
  }
}


:root {
  --loginBackColor: #0077FF;
  --loginInputTxt: #004CA3;
  --commonColor: #004CA3;

}

button:disabled {
  background-color: gray !important;
  cursor: not-allowed !important;
}

/* universal classes  */
.submitBtn {
  background-color: #004CA3 !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.g-1 {
  gap: 0.5rem;
}

.g-2 {
  gap: 1rem;

}

.g-3 {
  gap: 1.5rem;

}