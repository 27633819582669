.userFormCirculerStyleSec {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.circularDesignCircle {
    width: 120%;
    height: 250px;
    border-radius: 50%;
    background-color: var(--circularDesignCircleColor);
}

.circularDesignCircle:nth-child(1) {
    position: relative;
    top: -39%;
    z-index: 4;
}

.circularDesignCircle:nth-child(2) {
    position: absolute;
    z-index: 3;
    top: -32%;
}

.circularDesignCircle:nth-child(3) {
    position: absolute;
    z-index: 2;
    top: -25%;
}

.circularStyleImage {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 6;
}

.circularStyleText {
    color: white;
    position: absolute;
    top: 10%;
    z-index: 6;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circularStyleRotation {
    transform: rotateZ(180deg) !important;
}

.circularStyleRotation p {
    transform: rotateZ(-180deg) !important;
    
}

.circularStyleRotation img {
    transform: rotateZ(-180deg) translateX(50%) !important;
}
