@media (max-width:450px) {

    .getHelpPage {
        width: 100%;
        height: 80vh;
    }

    .getHelpPageBody {
        width: 100%;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        margin-top: 3rem;
    }

    .getHelpForm {
        width: 100%;
        /* border: 2px solid red; */
        border-radius: 6px;
        box-shadow: 0 0 27px -12px gray;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    .qritContactDetailsSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
    }

    .qritContactDetailsSection {
        width: 100%;
    }

    .contactType {
        width: 100%;
        text-align: start;
        color: var(--commonColor);
        font-size: 14px;
    }

    .contactDetailsCard {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
    }

    .contactDetailsCard a {
        text-decoration: none;
        color: black;
        font-size: 20px;
    }

    .getHelpPageImgSec {
        height: 8rem;
        width: 100%;
        width: fit-content;
        overflow: hidden;
    }

    .getHelpPageImg {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}