.header{
    height: 4rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: 0 1rem;
    border-bottom: 1px solid gray;
}
.backBtn{
    background: none;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.headerLabel{
font-weight: 700;
}