.listCard {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 27px -12px gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.listCardHistoryLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
    color: black;
}

.listCardHistoryLeft div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 4px;
}
.listCardHistoryLeft div span:nth-child(1) {
    
    font-size: 30px;
}
.listCardHistoryLeft div span:nth-child(1) {
    font-size: 25px;
}

.listCardStatus {
    color: var(--listCardStatusColor);
    font-weight: 600;
}

.listCardHistoryRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
}

.listcardDate {
    color: var(--commonColor);
    font-weight: 600;
}