.forgotPage {
    width: 100%;
    height: 100vh;
    /* border: 2px solid red; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

}

.forgotForm {
    width: 100%;
    flex: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0 1rem;
}

.upperSvgSec {
    border: 2px solid gray;
    width: 100%;
    height: 423px;
    overflow: hidden;
    top: 0;

}

.forgotUpperStyle {
    width: 120%;
    position: relative;
    height: 241px;
    top: 0;
    left: -42px;
    margin: auto;
}

.lowerSvgStyle {
    width: 100%;
    height: 243px;
    position: relative;
    overflow: hidden;
    bottom: -13px;

}

.forgotLowerStyle {
    width: 120%;
    position: relative;
    height: 241px;
    left: -35px;
    bottom: -13px;
}
.submitBtn{
    width: 100%;
    /* height: 2.rem; */
    font-size: 17px;
    color: white;
    text-align: center;
    background-color: #0077FF;
}