@media (max-width:450px) {
    .supplierChooseSec {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100vh;
    }
    .supplierChooseForm{
        width: 100%;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }
}